"use client";

import {
  BarSvg,
  CartOutlineSvg,
  ChevronDownSvg,
  ChevronRightSvg,
  ChevronUpSvg,
  IconGonMiniSvg,
  IconGonSvg,
  RemoveSvg,
  SearchSvg
} from "@/public/svg";
import MiniHeader from "../../sub-atom/MiniHeader";
import Button from "../Button";
import Input from "../Input";
import Image from "next/image";
import { AvatarImage } from "@/public/images";
import { twMerge } from "tailwind-merge";
import { memo, useEffect, useMemo, useState } from "react";
import Dropdown from "../Dropdown";
import Divider from "../Divider";
import { listMenuProfileHeader } from "@/functions/dinamisData";
import { usePathname, useRouter } from "next/navigation";
import { signIn, signOut, useSession } from "next-auth/react";
import Link from "next/link";
import checkLevel from "@/functions/checkLevel";
import { HeaderContext } from "@/functions/useHeader";
import useDelayedRender from "@/functions/useDelayedRender";
import { getCarts, revalidateCustom } from "@/functions/databaseData";
import { useQuery } from "@tanstack/react-query";

function Csr({ children, sortedCategories = [], profile = {}, session = {} }) {
  const router = useRouter();
  const pathname = usePathname();
  const shouldRendered = useDelayedRender(0);
  const [showSearch, setShowSearch] = useState(false);
  const [valueSearch, setValueSearch] = useState("");
  const [runHiddenClickOutside, setRunHiddenClickOutside] = useState(false);
  const [showDropdown, setShowDropdown] = useState({
    kategori: false,
    profile: false,
    menu: false
  });
  const { data: carts } = useQuery({
    queryFn: async () =>
      (await getCarts({
        id: session?.user?.data?.cart_id
      })) || {},
    queryKey: ["carts"]
  });

  const [isId, setIsId] = useState({
    datas: {}
  });

  const listMenu = listMenuProfileHeader({
    longged: profile?.actived
  });

  function handleShow(val) {
    setIsId({});
    setShowDropdown((old) => ({
      // ...old,
      [val]: !showDropdown?.[val]
    }));
  }

  const reduceSizeCart = Object.entries(carts?.products ?? {}).reduce(
    (accumulator, [_, item]) => accumulator + item.jumlah,
    0
  );
  const isSizeCart = reduceSizeCart;

  useEffect(() => {
    if (profile) {
      if (profile?.is_block) {
        signOut();
        return;
      }
    }
  }, [profile?.is_block]);

  useEffect(() => {
    if (
      new Date() > session?.user?.expirate_time ||
      session?.user?.expirate_time_status
    ) {
      signOut();
    }
  }, [session]);

  useEffect(() => {
    if (showDropdown?.menu || showDropdown?.kategori || showDropdown?.profile)
      setShowDropdown((old) => ({
        ...old,
        menu: false,
        kategori: false,
        profile: false
      }));
  }, [pathname]);

  const isListMenu = useMemo(
    () =>
      profile?.mitra_level < 5
        ? listMenu
        : listMenu?.map((val) => {
            let ka = val;
            if (ka?.title == "List Order") {
              ka.title = "Transaksi Saya";
              ka.uri = "/member-area/daftar-transaksi";
            }
            if (ka?.title == "Customer Saya") {
              ka.title = "Daftar Alamat";
              ka.uri = "/member-area/daftar-alamat";
            }
            return ka;
          }),
    [listMenu]
  );

  function goToSearch() {
    const section = document.getElementById("btn-link-search");
    if (section) section.click();
  }

  return (
    <HeaderContext.Provider
      value={{
        profile,
        session,
        status: useSession().status,
        carts
      }}
    >
      {pathname?.includes("signin") ||
      pathname?.includes("forget-password") ? null : (
        <header>
          <div className="top-0 z-50 fixed w-full">
            <div className={"flex flex-col shadow-normalGON"}>
              <>
                <MiniHeader />

                <Link
                  className="sr-only"
                  id="btn-link-search"
                  href={`/search/${valueSearch}`}
                  as={`/search/${valueSearch}`}
                  aria-label={"search"}
                  shallow
                  passHref
                  rel="preload"
                />

                {pathname == "/cek-stok" ? null : (
                  <div className="relative z-50">
                    <div
                      className={twMerge(
                        `hidden lg:flex w-full  bg-shadesGON-50 px-12 py-1`
                      )}
                    >
                      <ul className="flex w-full gap-12 items-center">
                        <li>
                          <Link
                            aria-label={"home"}
                            as={"/"}
                            href={"/"}
                            shallow
                            passHref
                            rel="preload"
                          >
                            <IconGonSvg className="w-12 h-12  xl:w-16 xl:h-16 text-primaryGON-400" />
                          </Link>
                        </li>
                        <li className="flex gap-4 items-center w-1/2">
                          <div id="kategori">
                            <Button
                              variants="text"
                              label="Kategori"
                              id="btn-show"
                              onHover={{
                                start: () => {
                                  handleShow("kategori");
                                  setRunHiddenClickOutside(true);
                                }
                              }}
                              colors="greyscale"
                              className={twMerge(
                                "text-primaryGON-500 hover:text-primaryGON-700 hover:bg-primaryGON-50 text-button-medium",
                                showDropdown?.kategori ? "bg-primaryGON-50" : ""
                              )}
                            />
                          </div>
                          <div id="search" className="w-full">
                            <Input
                              icon={{
                                left: (props) => <SearchSvg {...props} />
                              }}
                              value={valueSearch ?? ""}
                              placeholder="Cari produk by nama atau warna disini"
                              onChange={(val) => setValueSearch(val)}
                              onKeyDown={() => goToSearch()}
                              type="search"
                              enterKeyHint={"search"}
                            />
                          </div>
                        </li>
                      </ul>
                      <ul className="flex gap-12 items-center">
                        <li className="relative">
                          <div className="flex gap-6 items-center">
                            <Link
                              id="cart"
                              prefetch={true}
                              aria-label={`cart`}
                              className="relative cursor-pointer"
                              as={"/cart"}
                              shallow
                              href={"/cart"}
                              onClick={() =>
                                revalidateCustom({
                                  path: "/cart"
                                })
                              }
                            >
                              <div className="border border-primaryGON-50 p-3 rounded-full">
                                <CartOutlineSvg className="h-5 w-5 text-primaryGON-400" />
                              </div>
                              {isSizeCart > 0 && (
                                <div className="px-1.5 rounded-full bg-warningGON-400 absolute right-0 top-0 text-center items-center">
                                  <p className="p2-semiBold text-shadesGON-50">
                                    {isSizeCart > 99 ? "99+" : isSizeCart}
                                  </p>
                                </div>
                              )}
                            </Link>
                            {/* <div className='relative cursor-pointer'>
                      <div className='border border-primaryGON-50 p-3 rounded-full'>
                        <NotifOutlineSvg className='h-5 w-5 text-primaryGON-400' />
                      </div>
                      <div className='h-5 w-5 rounded-full bg-warningGON-400 absolute right-0 top-0' />
                      <div className='h-5 w-5 rounded-full bg-warningGON-400 absolute right-0 top-0 opacity-75 animate-ping' />
                    </div> */}
                            {!profile ? null : profile?.actived ? (
                              <div id="profile">
                                <Button
                                  variants="text"
                                  label={profile?.username?.split(" ")?.[0]}
                                  colors="greyscale"
                                  id="btn-show"
                                  onHover={{
                                    start: () => handleShow("profile")
                                  }}
                                  className="text-greyscaleGON-700"
                                  icon={{
                                    left: (props) => (
                                      <div
                                        id="btn-show"
                                        className="w-10 h-10 rounded-full bg-cover object-cover relative"
                                      >
                                        <Image
                                          unoptimized
                                          alt={`avatar-${1}`}
                                          src={
                                            profile?.avatar_url ?? AvatarImage
                                          }
                                          width={140}
                                          height={140}
                                          className="w-auto h-auto  bg-cover bg-center rounded-full"
                                          placeholder="blur"
                                          blurDataURL={AvatarImage?.blurDataURL}
                                        />
                                      </div>
                                    ),
                                    right: (props) =>
                                      showDropdown?.profile ? (
                                        <ChevronUpSvg {...props} />
                                      ) : (
                                        <ChevronDownSvg {...props} />
                                      )
                                  }}
                                />
                              </div>
                            ) : (
                              <Link
                                className="
                                  py-2 px-3 xs:py-3 xs:px-4 
                                  rounded flex justify-center items-center gap-2 border cursor-pointer select-none
                                  bg-primaryGON-400 border-primaryGON-400  hover:bg-primaryGON-500 focus-visible:bg-primaryGON-700  text-shadesGON-50"
                                aria-label={"Masuk"}
                                as={"/signin"}
                                shallow
                                passHref
                                href={"/signin"}
                              >
                                <span
                                  className={twMerge(
                                    "text-button-small xs:text-button-medium text-center capitalize z-0 max-w-full whitespace-nowrap overflow-hidden text-ellipsis"
                                  )}
                                >
                                  Masuk
                                </span>
                              </Link>
                            )}
                          </div>
                          <Dropdown
                            show={showDropdown?.profile}
                            hide={() => setShowDropdown({})}
                            className={"w-full min-w-[230px]"}
                          >
                            <div className="p-4 bg-shadesGON-50 w-full shadow-normalGON flex flex-col gap-4">
                              <div
                                onClick={() =>
                                  router.push("/member-area/profile-saya")
                                }
                                className="flex w-full gap-4 cursor-pointer"
                              >
                                <div className="w-[20%]">
                                  <div className="flex w-10 h-10 rounded-full bg-cover bg-primaryGON-50">
                                    <Image
                                      unoptimized
                                      alt={`avatar-${1}`}
                                      src={profile?.avatar_url ?? AvatarImage}
                                      width={140}
                                      height={140}
                                      className="w-auto h-auto bg-cover bg-center rounded-full"
                                      placeholder="blur"
                                      blurDataURL={AvatarImage?.blurDataURL}
                                    />
                                  </div>
                                </div>
                                <div className="flex flex-col w-[70%]">
                                  <span className="p1-semiBold text-greyscaleGON-700 capitalize truncate">
                                    {profile?.username ?? "Not Found"}
                                  </span>
                                  <span className="p3-regular text-greyscaleGON-700 capitalize">
                                    {checkLevel(profile?.mitra_level)}
                                  </span>
                                </div>
                              </div>
                              <Divider />
                              <div className="flex flex-col">
                                {isListMenu?.map((item, index) => {
                                  const Icon = item?.icon;
                                  const color = !item?.color
                                    ? "greyscale"
                                    : item?.color;

                                  return (
                                    <div key={index}>
                                      {(profile?.mitra_level > 2 &&
                                        (item?.uri ==
                                          "/member-area/dompet-digital" ||
                                          item?.uri ==
                                            "/member-area/jaringan-saya" ||
                                          (profile?.mitra_level > 4 &&
                                            item?.uri ==
                                              "/member-area/laporan-belanja"))) ||
                                      item?.title == "Kategori" ||
                                      // item?.title == "Notifikasi" ||
                                      item?.title ==
                                        "Cart" ? null : item?.title ===
                                        "Logout" ? (
                                        <div
                                          onClick={() => {
                                            signOut();
                                          }}
                                          className={twMerge(
                                            `flex gap-4 items-center text-${color}GON-400 cursor-pointer p-1`
                                          )}
                                        >
                                          {!item?.icon ? null : (
                                            <Icon className="h-5 w-5" />
                                          )}
                                          <span className="text-button-medium">
                                            {item?.title}
                                          </span>
                                        </div>
                                      ) : (
                                        <Link
                                          prefetch={true}
                                          aria-label={`${item?.title}`}
                                          as={item?.uri}
                                          shallow
                                          href={item?.uri}
                                          className={twMerge(
                                            `flex gap-4 items-center text-${color}GON-500 hover:text-primaryGON-400 cursor-pointer p-1`
                                          )}
                                        >
                                          {!item?.icon ? null : (
                                            <Icon className="h-5 w-5" />
                                          )}
                                          <span className="text-button-medium">
                                            {item?.title}
                                          </span>
                                        </Link>
                                      )}
                                      {item?.divider && <hr className="my-2" />}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </Dropdown>
                        </li>
                      </ul>
                    </div>
                    <div
                      className={twMerge(
                        "py-3",
                        `flex lg:hidden w-full bg-shadesGON-50 px-gon gap-4`
                      )}
                    >
                      <ul className="flex gap-4 items-center">
                        <li>
                          <Link
                            prefetch={true}
                            aria-label={`home`}
                            as="/"
                            shallow
                            href="/"
                          >
                            <IconGonMiniSvg className="w-12 h-12  xl:w-16 xl:h-16 text-primaryGON-400" />
                          </Link>
                        </li>
                        <div
                          id="kategori"
                          className="hidden sm:flex gap-4 items-center w-1/2"
                        >
                          <Button
                            variants="text"
                            label="Kategori"
                            id="btn-show"
                            onClick={() => handleShow("kategori")}
                            colors="greyscale"
                            className="text-greyscaleGON-700"
                            icon={{
                              right: (props) =>
                                showDropdown?.kategori ? (
                                  <ChevronUpSvg {...props} />
                                ) : (
                                  <ChevronDownSvg {...props} />
                                )
                            }}
                          />
                        </div>
                      </ul>
                      <ul className="flex w-full gap-2 items-center ">
                        <li className="flex gap-2 w-full justify-end items-end">
                          {showSearch ? (
                            <Input
                              icon={{
                                left: (props) => <SearchSvg {...props} />,
                                right: (props) => <RemoveSvg {...props} />,
                                onClickRight: () => setShowSearch(false)
                              }}
                              value={valueSearch ?? ""}
                              placeholder="Cari produk by nama atau warna disini..."
                              onChange={(val) => setValueSearch(val)}
                              onKeyDown={() => goToSearch()}
                              enterKeyHint={"search"}
                            />
                          ) : (
                            <>
                              <div
                                id="search"
                                onClick={() => setShowSearch(true)}
                                className="border border-primaryGON-50 p-3 rounded-full cursor-pointer"
                              >
                                <SearchSvg className="h-4 w-4 text-primaryGON-400" />
                              </div>
                              <Link
                                id="cart"
                                prefetch={true}
                                aria-label={`cart`}
                                className="relative cursor-pointer"
                                as={"/cart"}
                                shallow
                                href={"/cart"}
                                rel="preload"
                                onClick={() =>
                                  revalidateCustom({
                                    path: "/cart"
                                  })
                                }
                              >
                                <div className="border border-primaryGON-50 p-3 rounded-full">
                                  <CartOutlineSvg className="h-4 w-4 text-primaryGON-400" />
                                </div>
                                {isSizeCart > 0 && (
                                  <div className="px-1.5 rounded-full bg-warningGON-400 absolute right-0 top-0 text-center items-center">
                                    <p className="p2-semiBold text-shadesGON-50">
                                      {isSizeCart > 99 ? "99+" : isSizeCart}
                                    </p>
                                  </div>
                                )}
                              </Link>
                            </>
                          )}
                          <div
                            id="profile"
                            className="border border-primaryGON-50 p-3 rounded-full cursor-pointer"
                            onClick={() => handleShow("menu")}
                          >
                            <BarSvg className="h-4 w-4 text-primaryGON-400" />
                          </div>
                        </li>
                      </ul>
                    </div>

                    <Dropdown
                      show={showDropdown?.kategori}
                      hide={() => {
                        if (runHiddenClickOutside) {
                          setShowDropdown({});
                          setRunHiddenClickOutside(false);
                        }
                      }}
                    >
                      <div className="lg:flex hidden">
                        <div className="flex flex-col overflow-y-scroll h-full pb-44 max-h-screen xs:w-[250px] min-h-screen bg-shadesGON-50  py-4 gap-4 shadow-normalGON">
                          {sortedCategories.map((item, index) => {
                            const haveChild = item?.children?.length > 0;
                            return (
                              <Link
                                prefetch={true}
                                aria-label={`${item?.label}`}
                                key={index}
                                onMouseEnter={() =>
                                  setIsId((old) => ({
                                    datas: haveChild
                                      ? item?.label == "brand"
                                        ? {
                                            ["brand"]: item?.children
                                          }
                                        : {
                                            0: item?.children
                                          }
                                      : null
                                  }))
                                }
                                as={`/search/${
                                  item?.label == "brand"
                                    ? item?.label
                                    : item?.isSpecialMenu
                                    ? "special-menu"
                                    : "category"
                                }/${
                                  item?.label == "brand" ? "nbrs" : item?.label
                                }`}
                                shallow
                                href={`/search/${
                                  item?.label == "brand"
                                    ? item?.label
                                    : item?.isSpecialMenu
                                    ? "special-menu"
                                    : "category"
                                }/${
                                  item?.label == "brand" ? "nbrs" : item?.label
                                }`}
                                onClick={() => {
                                  setShowDropdown((old) => ({
                                    ...old,
                                    kategori: false
                                  }));
                                }}
                                className="flex flex-row items-center justify-between cursor-pointer px-gon py-2 text-greyscaleGON-700 hover:text-primaryGON-400"
                              >
                                <p className="text-button-small xs:text-button-medium capitalize z-0 max-w-full whitespace-nowrap overflow-hidden text-ellipsis truncate">
                                  {item?.label}
                                </p>
                                {haveChild && (
                                  <div>
                                    <ChevronRightSvg className="w-4 h-4" />
                                  </div>
                                )}
                              </Link>
                            );
                          })}
                        </div>

                        {Object.entries(isId?.datas ?? {})?.map(
                          ([key, item], idx) => {
                            return (
                              <div
                                key={idx}
                                className="flex flex-col w-full overflow-y-scroll h-full pb-44 max-h-screen xs:w-[250px] min-h-screen bg-shadesGON-50 py-4 gap-4 shadow-normalGON"
                              >
                                {item?.map((item, index) => {
                                  const lengisId = Number(key) + 1;
                                  const haveChild = item?.children?.length > 0;

                                  return (
                                    <Link
                                      prefetch={true}
                                      aria-label={`${item?.label}`}
                                      key={index}
                                      onMouseEnter={() => {
                                        haveChild &&
                                          lengisId <= 1 &&
                                          setIsId((old) => ({
                                            ...old,
                                            datas: {
                                              ...old?.datas,
                                              [lengisId]: item?.children
                                            }
                                          }));
                                      }}
                                      as={`/search/${
                                        key == "brand" ? key : "category"
                                      }/${item?.label}`}
                                      shallow
                                      href={`/search/${
                                        key == "brand" ? key : "category"
                                      }/${item?.label}`}
                                      onClick={() => {
                                        setShowDropdown((old) => ({
                                          ...old,
                                          kategori: false
                                        }));
                                      }}
                                      className="flex flex-row items-center justify-between cursor-pointer px-gon py-2 text-greyscaleGON-700 hover:text-primaryGON-400"
                                    >
                                      <p className="text-button-small xs:text-button-medium capitalize z-0 max-w-full whitespace-nowrap overflow-hidden text-ellipsis truncate">
                                        {item?.label}
                                      </p>
                                      {haveChild && (
                                        <div>
                                          <ChevronRightSvg className="w-4 h-4" />
                                        </div>
                                      )}
                                    </Link>
                                  );
                                })}
                              </div>
                            );
                          }
                        )}
                      </div>
                      <div className="flex lg:hidden h-auto">
                        <div className=" w-screen min-h-screen bg-shadesGON-50 px-gon py-4 shadow-normalGON overflow-y-scroll h-full pb-44 max-h-screen">
                          {sortedCategories.map((item1, index) => {
                            const haveChild = item1?.children?.length > 0;
                            const showCateChild =
                              isId?.label?.[0] == item1?.label;
                            const Icon = showCateChild
                              ? (props) => (
                                  <div
                                    className="relative"
                                    onClick={() => {
                                      setIsId((old) => ({
                                        ...old,
                                        label:
                                          item1?.label == "brand"
                                            ? {
                                                ...old?.label,
                                                ["brand"]: showCateChild
                                                  ? ""
                                                  : item1?.label
                                              }
                                            : {
                                                ...old?.label,
                                                0: showCateChild
                                                  ? ""
                                                  : item1?.label
                                              }
                                      }));
                                    }}
                                  >
                                    <ChevronUpSvg {...props} />
                                  </div>
                                )
                              : (props) => (
                                  <div
                                    className="relative"
                                    onClick={() => {
                                      setIsId((old) => ({
                                        ...old,
                                        label:
                                          item1?.label == "label"
                                            ? {
                                                ...old?.label,
                                                ["brand"]: showCateChild
                                                  ? ""
                                                  : item1?.label
                                              }
                                            : {
                                                ...old?.label,
                                                0: showCateChild
                                                  ? ""
                                                  : item1?.label
                                              }
                                      }));
                                    }}
                                  >
                                    <ChevronDownSvg {...props} />
                                  </div>
                                );
                            return (
                              <div key={index}>
                                {haveChild ? (
                                  <div
                                    onClick={() => {
                                      setIsId((old) => ({
                                        ...old,
                                        label: {
                                          ...old?.label,
                                          0: showCateChild ? "" : item1?.label
                                        }
                                      }));
                                    }}
                                    className="flex flex-row items-center justify-between cursor-pointer px-gon py-2 text-greyscaleGON-700 hover:text-primaryGON-400"
                                  >
                                    <Link
                                      prefetch={true}
                                      aria-label={`${item1?.label}`}
                                      as={`/search/${
                                        item1?.label == "brand"
                                          ? item1?.label
                                          : "category"
                                      }/${
                                        item1?.label == "brand"
                                          ? "nbrs"
                                          : item1?.label
                                      }`}
                                      shallow
                                      href={`/search/${
                                        item1?.label == "brand"
                                          ? item1?.label
                                          : "category"
                                      }/${
                                        item1?.label == "brand"
                                          ? "nbrs"
                                          : item1?.label
                                      }`}
                                    >
                                      <p className="text-button-small xs:text-button-medium capitalize z-0 max-w-full whitespace-nowrap overflow-hidden text-ellipsis truncate">
                                        {item1?.label}
                                      </p>
                                    </Link>
                                    <div className="relative z-10">
                                      <Icon className="w-4 h-4" />
                                    </div>
                                  </div>
                                ) : (
                                  <Link
                                    prefetch={true}
                                    aria-label={`${item1?.label}`}
                                    as={`/search/${
                                      item1?.label == "brand"
                                        ? item1?.label
                                        : item1?.isSpecialMenu
                                        ? "special-menu"
                                        : "category"
                                    }/${
                                      item1?.label == "brand"
                                        ? "nbrs"
                                        : item1?.label
                                    }`}
                                    shallow
                                    href={`/search/${
                                      item1?.label == "brand"
                                        ? item1?.label
                                        : item1?.isSpecialMenu
                                        ? "special-menu"
                                        : "category"
                                    }/${
                                      item1?.label == "brand"
                                        ? "nbrs"
                                        : item1?.label
                                    }`}
                                    className="flex flex-row items-center justify-between cursor-pointer px-gon py-2 text-greyscaleGON-700 hover:text-primaryGON-400"
                                  >
                                    <p className="text-button-small xs:text-button-medium capitalize z-0 max-w-full whitespace-nowrap overflow-hidden text-ellipsis truncate">
                                      {item1?.label}
                                    </p>
                                  </Link>
                                )}

                                <Divider />
                                {showCateChild &&
                                  item1?.children?.map((item, index) => {
                                    const haveChild =
                                      item?.children?.length > 0;
                                    const showCateChild_1 =
                                      isId?.label?.[1] == item?.label;
                                    const Icon = showCateChild_1
                                      ? (props) => (
                                          <div
                                            className="relative"
                                            onClick={() => {
                                              setIsId((old) => ({
                                                ...old,
                                                label: {
                                                  ...old?.label,
                                                  1: showCateChild_1
                                                    ? ""
                                                    : item?.label
                                                }
                                              }));
                                            }}
                                          >
                                            <ChevronUpSvg {...props} />
                                          </div>
                                        )
                                      : (props) => (
                                          <div
                                            className="relative"
                                            onClick={() => {
                                              setIsId((old) => ({
                                                ...old,
                                                label: {
                                                  ...old?.label,
                                                  1: showCateChild_1
                                                    ? ""
                                                    : item?.label
                                                }
                                              }));
                                            }}
                                          >
                                            <ChevronDownSvg {...props} />
                                          </div>
                                        );
                                    return (
                                      <div key={index} className="pl-gon">
                                        {haveChild ? (
                                          <div
                                            onClick={() => {
                                              setIsId((old) => ({
                                                ...old,
                                                label: {
                                                  ...old?.label,
                                                  1: showCateChild_1
                                                    ? ""
                                                    : item?.label
                                                }
                                              }));
                                            }}
                                            className="flex flex-row items-center justify-between cursor-pointer px-gon py-2 text-greyscaleGON-700 hover:text-primaryGON-400"
                                          >
                                            <Link
                                              prefetch={true}
                                              aria-label={`${item?.label}`}
                                              as={`/search/${
                                                item1?.label == "brand"
                                                  ? item1?.label
                                                  : "category"
                                              }/${item?.label}`}
                                              shallow
                                              href={`/search/${
                                                item1?.label == "brand"
                                                  ? item1?.label
                                                  : "category"
                                              }/${item?.label}`}
                                            >
                                              <p className="text-button-small xs:text-button-medium capitalize z-0 max-w-full whitespace-nowrap overflow-hidden text-ellipsis truncate">
                                                {item?.label}
                                              </p>
                                            </Link>
                                            <div className="relative z-10">
                                              <Icon className="w-4 h-4" />
                                            </div>
                                          </div>
                                        ) : (
                                          <Link
                                            prefetch={true}
                                            aria-label={`${item?.label}`}
                                            as={`/search/${
                                              item1?.label == "brand"
                                                ? item1?.label
                                                : "category"
                                            }/${item?.label}`}
                                            shallow
                                            href={`/search/${
                                              item1?.label == "brand"
                                                ? item1?.label
                                                : "category"
                                            }/${item?.label}`}
                                            className="flex flex-row items-center justify-between cursor-pointer px-gon py-2 text-greyscaleGON-700 hover:text-primaryGON-400"
                                          >
                                            <p className="text-button-small xs:text-button-medium capitalize z-0 max-w-full whitespace-nowrap overflow-hidden text-ellipsis truncate">
                                              {item?.label}
                                            </p>
                                          </Link>
                                        )}
                                        <Divider />
                                        {showCateChild_1 &&
                                          item?.children?.map((item, index) => {
                                            const haveChild =
                                              item?.children?.length > 0;
                                            const showCateChild_2 =
                                              isId?.label?.[2] == item?.label;
                                            const Icon = showCateChild_2
                                              ? (props) => (
                                                  <div
                                                    className="relative"
                                                    onClick={() => {
                                                      setIsId((old) => ({
                                                        ...old,
                                                        label: {
                                                          ...old?.label,
                                                          2: showCateChild_2
                                                            ? ""
                                                            : item?.label
                                                        }
                                                      }));
                                                    }}
                                                  >
                                                    <ChevronUpSvg {...props} />
                                                  </div>
                                                )
                                              : (props) => (
                                                  <div
                                                    className="relative"
                                                    onClick={() => {
                                                      setIsId((old) => ({
                                                        ...old,
                                                        label: {
                                                          ...old?.label,
                                                          2: showCateChild_2
                                                            ? ""
                                                            : item?.label
                                                        }
                                                      }));
                                                    }}
                                                  >
                                                    <ChevronDownSvg
                                                      {...props}
                                                    />
                                                  </div>
                                                );
                                            return (
                                              <div
                                                key={index}
                                                className="pl-gon"
                                              >
                                                {haveChild ? (
                                                  <div
                                                    onClick={() => {
                                                      setIsId((old) => ({
                                                        ...old,
                                                        label: {
                                                          ...old?.label,
                                                          2: showCateChild_2
                                                            ? ""
                                                            : item?.label
                                                        }
                                                      }));
                                                    }}
                                                    className="flex flex-row items-center justify-between cursor-pointer px-gon py-2 text-greyscaleGON-700 hover:text-primaryGON-400"
                                                  >
                                                    <Link
                                                      prefetch={true}
                                                      aria-label={`${item?.label}`}
                                                      as={`/search/category/${item?.label}`}
                                                      shallow
                                                      href={`/search/category/${item?.label}`}
                                                    >
                                                      <p className="text-button-small xs:text-button-medium capitalize z-0 max-w-full whitespace-nowrap overflow-hidden text-ellipsis truncate">
                                                        {item?.label}
                                                      </p>
                                                    </Link>
                                                    <div className="relative z-10">
                                                      <Icon className="w-4 h-4" />
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <Link
                                                    prefetch={true}
                                                    aria-label={`${item?.label}`}
                                                    as={`/search/category/${item?.label}`}
                                                    shallow
                                                    href={`/search/category/${item?.label}`}
                                                    className="flex flex-row items-center justify-between cursor-pointer px-gon py-2 text-greyscaleGON-700 hover:text-primaryGON-400"
                                                  >
                                                    <p className="text-button-small xs:text-button-medium capitalize z-0 max-w-full whitespace-nowrap overflow-hidden text-ellipsis truncate">
                                                      {item?.label}
                                                    </p>
                                                  </Link>
                                                )}
                                                <Divider />
                                              </div>
                                            );
                                          })}
                                      </div>
                                    );
                                  })}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </Dropdown>

                    <Dropdown
                      show={showDropdown?.menu}
                      className={
                        "flex lg:hidden h-auto bg-shadesGON-50 w-screen py-4 overflow-y-scroll pb-44 max-h-screen"
                      }
                    >
                      <div className="w-screen min-h-screen bg-shadesGON-50 px-gon py-4 shadow-normalGON flex flex-col gap-6">
                        {isListMenu?.map((item, index) => {
                          const Icon = item?.icon;
                          const color = !item?.color
                            ? "greyscale"
                            : item?.color;

                          return (profile?.mitra_level > 2 &&
                            (item?.uri == "/member-area/dompet-digital" ||
                              item?.uri == "/member-area/jaringan-saya" ||
                              (profile?.mitra_level > 4 &&
                                item?.uri ==
                                  "/member-area/laporan-belanja"))) ||
                            item?.title == "Cart" ? null : item?.title ===
                              "Logout" || item?.title == "Kategori" ? (
                            <div
                              key={index}
                              onClick={() => {
                                if (item?.title === "Logout") {
                                  signOut();
                                } else handleShow("kategori");

                                setShowDropdown((old) => ({
                                  ...old,
                                  menu: false
                                }));
                              }}
                              // href={item?.title !== "Logout" ?  : ''}
                              className={twMerge(
                                item?.title == "Logout"
                                  ? `text-${color}GON-400`
                                  : `text-${color}GON-500 hover:text-primaryGON-400`,
                                `flex gap-4 items-center cursor-pointer`
                              )}
                            >
                              {!item?.icon ? null : (
                                <Icon className="h-5 w-5" />
                              )}
                              <span className="text-button-medium">
                                {item?.title}
                              </span>
                            </div>
                          ) : (
                            <Link
                              prefetch={true}
                              aria-label={`${item?.title}`}
                              key={index}
                              as={item?.uri}
                              shallow
                              href={item?.uri}
                              className={twMerge(
                                `flex gap-4 items-center text-${color}GON-500 hover:text-primaryGON-400 cursor-pointer`
                              )}
                            >
                              <div className="relative">
                                {!item?.icon ? null : (
                                  <Icon className="h-5 w-5" />
                                )}
                              </div>
                              <span className="text-button-medium">
                                {item?.title}
                              </span>
                            </Link>
                          );
                        })}
                      </div>
                    </Dropdown>
                  </div>
                )}
              </>
            </div>
          </div>
        </header>
      )}
      {children}
    </HeaderContext.Provider>
  );
}

export default memo(Csr);
